export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0];

export const dictionary = {
		"/(public)": [56,[7]],
		"/(app)/account": [22,[2]],
		"/(app)/account/device": [23,[2]],
		"/(app)/auth/docs": [24,[2,3]],
		"/(public)/auth/email/verify": [57,[7]],
		"/(public)/auth/forgot-password": [58,[7]],
		"/(public)/auth/reset-password": [59,[7]],
		"/(app)/contact": [25,[2]],
		"/(app)/crucible": [26,[2,4]],
		"/(app)/crucible/dashboard": [28,[2,4]],
		"/(app)/crucible/event/join/[groupCode]": [32,[2,4]],
		"/(app)/crucible/event/join/[groupCode]/[teamCode]": [33,[2,4]],
		"/(app)/crucible/event/[groupKey]": [29,[2,4]],
		"/(app)/crucible/event/[groupKey]/info": [30,[2,4]],
		"/(app)/crucible/event/[groupKey]/leaderboard": [31,[2,4]],
		"/(app)/crucible/series": [34,[2,4]],
		"/(app)/crucible/[challengeKey]": [27,[2,4]],
		"/(public)/privacy": [60,[7]],
		"/(public)/redirect": [61,[7]],
		"/(app)/spyglass/datasets": [35,[2,5]],
		"/(app)/spyglass/projects": [36,[2,5]],
		"/(app)/spyglass/projects/[projectKey]": [37,[2,5]],
		"/(app)/spyglass/projects/[projectKey]/[runKey]": [38,[2,5]],
		"/(app)/spyglass/scorers": [39,[2,5]],
		"/(app)/spyglass/signing": [40,[2,5]],
		"/(app)/spyglass/targets": [41,[2,5]],
		"/(app)/strikes": [42,[2,6]],
		"/(app)/strikes/agents": [44,[2,6]],
		"/(app)/strikes/agents/[agentKey]": [45,[2,6]],
		"/(app)/strikes/agents/[agentKey]/runs/[runId]": [46,[2,6]],
		"/(app)/strikes/groups": [47,[2,6]],
		"/(app)/strikes/groups/[groupKey]": [48,[2,6]],
		"/(app)/strikes/overview": [49,[2,6]],
		"/(app)/strikes/projects": [50,[2,6]],
		"/(app)/strikes/projects/[projectKey]": [51,[2,6]],
		"/(app)/strikes/projects/[projectKey]/[runId]": [52,[2,6]],
		"/(app)/strikes/runs": [53,[2,6]],
		"/(app)/strikes/runs/[shareId]": [54,[2,6]],
		"/(app)/strikes/[strikeKey]": [43,[2,6]],
		"/(app)/team/invitation/[inviteCode]": [55,[2]],
		"/(public)/terms": [62,[7]],
		"/(public)/verify": [63,[7]],
		"/(app)/[sandbox=dev]": [8,[2]],
		"/(app)/[sandbox=dev]/stories": [9,[2]],
		"/(app)/[sandbox=dev]/stories/avatar": [10,[2]],
		"/(app)/[sandbox=dev]/stories/button": [11,[2]],
		"/(app)/[sandbox=dev]/stories/code-block": [12,[2]],
		"/(app)/[sandbox=dev]/stories/colors": [13,[2]],
		"/(app)/[sandbox=dev]/stories/dhtmlx-gantt": [14,[2]],
		"/(app)/[sandbox=dev]/stories/drawer": [15,[2]],
		"/(app)/[sandbox=dev]/stories/filters-menu": [16,[2]],
		"/(app)/[sandbox=dev]/stories/item-dropdown": [17,[2]],
		"/(app)/[sandbox=dev]/stories/json-viewer": [18,[2]],
		"/(app)/[sandbox=dev]/stories/panel-tab": [19,[2]],
		"/(app)/[sandbox=dev]/stories/pill": [20,[2]],
		"/(app)/[sandbox=dev]/stories/text-input": [21,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';